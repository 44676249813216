
<!-- this component should not use slots -->
<!-- this component should not inherit attrs -->

<!-- need to test mobile when making any changes -->

<script lang="ts">
    export interface IProps {
        /* prop */
        size?: "sm" | "md" | "lg";
        /* prop */
        text?: string;
        /* prop */
        loading?: boolean;
    };

    export interface IEmits {
        (e: "change", val: boolean): void;
    };
</script>

<script setup lang="ts">
    import { ref, computed, watch } from "vue";
    import { isMobile } from "o365.GlobalState.ts";

    const props = withDefaults(defineProps<IProps>(), { size: "md" });
    const emit = defineEmits<IEmits>();
    const model = defineModel<boolean>();

    const isLoading = computed<boolean>(() => props.loading);

    function getFontSize(): string {
        if (isMobile.value) {
            return "1.25em";
        }
        if (props.size === "sm") {
            return "0.75em";
        } else if (props.size === "lg") {
            return "1.25em";
        }
        // md and everything else
        return "1.000em";
    }

    watch(model, (val) => emit("change", !!val));
</script>

<template>
    <div>
        <a class="d-block" :style="{ 'margin': isMobile ? '-0.5rem' : undefined, 'padding': isMobile ? '0.5rem' : undefined }" @click="model = !model">
            <div
                class="border"
                :class="{ 'bg-primary': model, 'bg-white': !model }"
                style="font-size: 1rem; height: 1.5em; width: 2.75em; border-radius: 999px; position: relative;"
                :style="{ 'font-size': getFontSize() }"
            >
                <div
                    class="c-switch-dot d-flex justify-content-center align-items-center"
                    style="width: 1em; height: 1em; border-radius: 100%; position: absolute; left: 50%; top: 50%;"
                    :style="{ 'transform': model ? 'translate(calc(-50% + 0.625em), -50%)' : 'translate(calc(-50% - 0.625em), -50%)' }"
                >
                    <template v-if="isLoading">
                        <div class="c-spinner spinner-border spinner-border-sm" :style="{ 'color': model ? 'var(--bs-white)' : 'var(--bs-gray-500)' }" />
                    </template>
                    <template v-else>
                        <div
                            style="width: 1em; height: 1em; border-radius: 100%;"
                            :style="{ 'background': model ? 'var(--bs-white)' : 'var(--bs-gray-500)' }"
                        >
                        </div>
                    </template>
                </div>
            </div>
        </a>
    </div>
</template>

<style scoped>
    .c-spinner {
        --bs-spinner-width: 1em;
        --bs-spinner-height: 1em;
        --bs-spinner-border-width: 0.2em;
    }

    .c-switch-dot {
        transition: all ease-in-out 150ms;
    }
</style>
